<template>
  <e-modal
    :visible="visible"
    title="反结算订单编辑"
    :width="800"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <div class="fjs-box">
      <div class="order-info">
        <div style="display: flex;justify-content: space-between;" v-if="orderDetail">
          <span>
            <div>
              {{ orderDetail.comboName }}
              <span class="canbiao"> ¥{{ orderDetail.comboPrice }}元/人 </span>
            </div>
          </span>
          <span style="margin-right: -8px">
            <a-tag color="#108ee9" v-if="!isEditCombo">
              <a @click="editCombo" style="color: #fff; font-weight: 400">
                修改套餐
              </a>
            </a-tag>
            <a-tag color="#f50" v-if="isEditCombo">
              <a @click="onEditCombo" style="color: #fff; font-weight: 400">
                保存
              </a>
            </a-tag>
            <a-tag color="#2db7f5" v-if="isEditCombo">
              <a @click="editCombo" style="color: #fff; font-weight: 400">
                取消
              </a>
            </a-tag>
          </span>
        </div>

        <div style="padding-top: 30px" v-if="isEditCombo">
          <CSelect
            title="修改餐标"
            :labelCol="7"
            :valueCol="14"
            v-model:value="changeComboData.comboPrice"
            :options="comboUnit"
            @ok="onChangeComboUnit"
          />
          <CSelect
            title="修改人数"
            :labelCol="7"
            :valueCol="14"
            v-model:value="changeComboData.peopleCount"
            :options="peoples"
            @ok="onChangePeoples"
          />
          <CSelect
            title="选择套餐"
            :labelCol="7"
            :valueCol="14"
            v-model:value="changeComboData.comboId"
            :options="combos"
            @ok="onChangeCombo"
          />
        </div>

        <OrderInfo
          v-if="orderId"
          ref="orderInfo"
          :orderId="orderId"
          :showCombo="true"
          @change="onGetOrder"
        />
      </div>
      <div class="pay-info" v-if="orderDetail">
        <div class="line">
          <div class="label">支付方式：</div>
          <div class="value">
            {{
              [
                "未付款",
                "全民付",
                "扫码支付",
                "会员支付",
                "刷卡支付",
                "现金支付",
                "支付宝扫码",
                "微信扫码",
                "挂账",
              ][orderDetail.payChannel]
            }}
          </div>
        </div>

        <div class="line" v-if="orderDetail.payChannel === 8">
          <div class="label">挂账客户：</div>
          <div class="value">{{ orderDetail.customerMobile }}</div>
        </div>

        <div class="line">
          <div class="label">订单金额：</div>
          <div class="value">{{ orderDetail.orderAmount }}</div>
        </div>
        <div class="line">
          <div class="label">折扣金额：</div>
          <div class="value">{{ orderDetail.disCountAmount }}</div>
        </div>
        <div class="line">
          <div class="label">优惠金额：</div>
          <div class="value">{{ orderDetail.savedAmount }}</div>
        </div>
        <div class="line">
          <div class="label">抹零金额：</div>
          <div class="value">{{ orderDetail.wipeOffAmount }}</div>
        </div>
        <div class="line">
          <div class="label">支付金额：</div>
          <div class="value">{{ orderDetail.payAmount }}</div>
        </div>
      </div>
      <div class="pay-info" v-else>正在查询订单信息</div>
    </div>
  </e-modal>
</template>

<script>
import { defineComponent, ref, createVNode, reactive, toRaw } from 'vue'
import OrderInfo from '../OrderInfo'
import { OrderClass } from '@/apis/order'
import { Modal, message } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import CSelect from '@/components/counter/form/Select'

const order = new OrderClass()
export default defineComponent({
  emits: ['ok'],
  props: {
    allowRoles: {
      type: Array,
      default: () => []
    }
  },
  components: {
    OrderInfo,
    CSelect
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const orderId = ref('')
    const orderDetail = ref()

    const changeComboData = reactive({
      comboPrice: 0,
      peopleCount: 0,
      id: 0,
      comboId: 0
    })
    const allCombos = ref([])
    const combos = ref([])
    const comboUnit = ref([])
    const peoples = ref([])
    const combo = ref('')
    const isEditCombo = ref(false)

    const onClose = () => {
      isEditCombo.value = false
      loading.value = false
      visible.value = false
    }

    const onSubmitFun = () => {
      Modal.confirm({
        title: '警告！',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请确定是否完成反结算'
        ),
        okText: '确定完成',
        onOk () {
          order.orderType(orderId.value, 4).then(() => {
            message.success('反结算完成')
            emit('ok')
            onClose()
          })
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const onGetOrder = (callBack) => {
      loading.value = true
      order.detail(orderId.value).then((resp) => {
        orderDetail.value = resp

        changeComboData.comboId = resp.comboId
        changeComboData.comboPrice = resp.comboPrice
        changeComboData.peopleCount = resp.peopleCount
        changeComboData.id = resp.id

        typeof callBack === 'function' ? callBack() : (callBack = null)
      })
    }

    const editCombo = () => {
      isEditCombo.value = !isEditCombo.value

      if (isEditCombo.value) {
        const combolist = allCombos.value.filter(
          (x) =>
            x.peopleCount === changeComboData.peopleCount &&
            x.unitPrice === changeComboData.comboPrice
        )

        const combosV = []

        combolist.map((e) => {
          combosV.push({
            label: e.name,
            value: e.id
          })
        })

        combos.value = combosV
      }
    }

    const onChangeCombo = (e) => {
      changeComboData.comboId = e
    }

    const onChangeComboUnit = (e) => {
      const all = allCombos.value.filter((x) => x.unitPrice === e)
      const comboList = allCombos.value.filter(
        (x) =>
          x.unitPrice === e && x.peopleCount === changeComboData.peopleCount
      )

      changeComboData.comboId = ''

      const selectCombos = []
      const selectPeoples = []
      const selectPeoplesV = []

      all.map((e) => {
        if (!selectPeoples.includes(e.peopleCount)) {
          selectPeoples.push(e.peopleCount)
        }
      })

      comboList.map((e) => {
        if (selectCombos.filter((x) => x.label === e.name).length === 0) {
          selectCombos.push({
            label: e.name,
            value: e.id
          })
        }
      })

      selectPeoples.map((e) => {
        selectPeoplesV.push({
          label: e + '人',
          value: e
        })
      })

      peoples.value = selectPeoplesV
      combos.value = selectCombos

      const cur = changeComboData.peopleCount
      const has = selectPeoplesV.filter((x) => x.value === cur)
      if (has.length === 0) {
        changeComboData.peopleCount = selectPeoplesV[0].value
      }
    }

    const onChangePeoples = (e) => {
      const all = allCombos.value.filter((x) => x.peopleCount === e)
      const comboList = allCombos.value.filter(
        (x) => x.unitPrice === changeComboData.comboPrice && x.peopleCount === e
      )

      changeComboData.comboId = ''

      const selectCombos = []
      const selectComboUnits = []
      const selectComboUnitsV = []
      all.map((e) => {
        if (!selectComboUnits.includes(e.unitPrice)) {
          selectComboUnits.push(e.unitPrice)
        }
      })

      comboList.map((e) => {
        if (selectCombos.filter((x) => x.label === e.name).length === 0) {
          selectCombos.push({
            label: e.name,
            value: e.id
          })
        }
      })
      selectComboUnits.map((e) => {
        selectComboUnitsV.push({
          label: e + '元',
          value: e
        })
      })

      comboUnit.value = selectComboUnitsV
      combos.value = selectCombos

      const cur = changeComboData.comboPrice
      const has = selectComboUnitsV.filter((x) => x.value === cur)
      if (has.length === 0) {
        changeComboData.comboPrice = selectComboUnitsV[0].value
      }
    }

    const onEditCombo = () => {
      const data = { ...toRaw(changeComboData) }

      loading.value = true
      order
        .update(data)
        .then(() => {
          isEditCombo.value = false
          onGetOrder()
        })
        .catch(() => {
          loading.value = false
        })
    }

    return {
      visible,
      loading,
      orderId,
      orderDetail,
      changeComboData,
      combos,
      comboUnit,
      peoples,
      combo,
      allCombos,
      isEditCombo,

      onClose,
      onSubmitFun,
      onGetOrder,
      onEditCombo,
      onChangePeoples,
      onChangeCombo,
      onChangeComboUnit,
      editCombo
    }
  },

  created () {
    this.$watch('orderDetail', e => {
      this.$refs.orderInfo.onRefresh()
    }, { deep: true })
  },
  methods: {
    open (orderId) {
      this.orderId = orderId
      this.onGetOrder()
      this.onGetAllCombos()

      this.visible = true
    },

    async onGetAllCombos () {
      const list =
        this.$store.state.data.ALL_COMBOS.length > 0
          ? this.$store.state.data.ALL_COMBOS
          : await this.$store.dispatch('GetAllCombos')
      this.allCombos = list

      const peoples = []
      const comboUnit = []
      const len = list.length
      for (let i = 0; i < len; i++) {
        if (!peoples.includes(list[i].peopleCount)) {
          peoples.push(list[i].peopleCount)
        }
      }

      for (let i = 0; i < len; i++) {
        if (!comboUnit.includes(list[i].unitPrice)) {
          comboUnit.push(list[i].unitPrice)
        }
      }

      const selectPeoples = []
      const selectCombos = []

      peoples.map((e) => {
        selectPeoples.push({
          label: e + '人',
          value: e
        })
      })

      comboUnit.map((e) => {
        selectCombos.push({
          label: e + '元',
          value: e
        })
      })

      this.peoples = selectPeoples
      this.comboUnit = selectCombos
    }
  }
})
</script>

<style lang="less" scoped>
.fjs-box {
  display: flex;
  justify-content: space-between;
}
.order-info {
  width: 400px;
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* 滚动槽 */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d3eaff;
  }
}
.pay-info {
  width: 250px;
  .line {
    display: flex;
    line-height: 24px;
    font-size: 12px;
  }
}
</style>
